<template>
  <div id="home">
    <van-nav-bar
        title="我的预约"
        :left-arrow="true"
        @click-left="goback('/ucenter');"
        fixed
    />
    <div class="login profile">
      <div class="v-tabs">
        <span :class="searchinfo.status==0?'current':''" @click.stop="change(0)">未完成({{number.needpaynum}})</span>
        <span :class="searchinfo.status==1?'current':''" @click.stop="change(1)">已预约({{number.haspaynum}})</span>
        <span :class="searchinfo.status==2?'current':''" @click.stop="change(2)">已完成({{number.finishnum}})</span>
      </div>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getlist"
      >
        <div class="v-cell" v-for="item in orderlist" :key="item.id">
          <ul>
            <li>
              <span></span>
              <div>{{ item.order_sn }}</div>
              <div>{{ item.created_at }}
                <div style="display: inline-block;" v-if="searchinfo.status">
                  <em v-if="item.is_refund == 1">[退款中]</em>
                  <em v-else-if="item.is_refund == 2" style="color:#888888;">[已取消]</em>
                  <em v-else-if="item.is_deal == 0 && (item.is_refund == 0 || item.is_refund == 3)" style="color:#888888;">[待服务]</em>
                  <em v-else-if="item.is_deal == 1 && (item.is_refund == 0 || item.is_refund == 3)">[服务中]</em>
                  <em v-else-if="item.is_deal == 2 && (item.is_refund == 0 || item.is_refund == 3)">[待付尾款]</em>
                  <em v-else-if="item.is_deal == 3 && (item.is_refund == 0 || item.is_refund == 3)">[待评价]</em>
                  <em v-else-if="item.is_deal == 4 && (item.is_refund == 0 || item.is_refund == 3)" style="color:green;">[已完成]</em>
                </div>
              </div>
            </li>
            <li style="text-align: left;">{{ getyytimestr(item.masseur_name,item.daytime,item.datetime) }}</li>
            <li v-if="item.pay_status == 0">
              <van-button class="cancel" :loading="loading" @click.stop="ordercancel(item)">取消订单</van-button>
              <van-button class="gopay" :loading="gopayloading" @click.stop="gopayend(item,'pay')">去支付</van-button>
            </li>
            <li v-else-if="item.pay_status == 1 && item.is_deal == 0 && item.is_refund == 0">
              <van-button class="gopay" :loading="loading" @click.stop="orderapply(item)">申请退款</van-button>
            </li>
            <li v-else-if="item.pay_status == 1 && item.is_deal == 2">
              <van-button class="gopay" :loading="gopayloading" @click.stop="gopayend(item,'end')">支付尾款({{item.final_price}})</van-button>
            </li>
            <li class="o-c" v-else-if="item.pay_status ==1 && item.is_deal > 2">
              <van-tabs v-model="active">
                <van-tab>
                  <template #title> <van-icon name="chat-o" />服务评价</template>
                  <div class="o-c-info">
                    <div v-if="item.pay_status==1 && item.is_deal==3">
                      <van-field
                          v-model="item.custom_remarks"
                          type="textarea"
                          placeholder="请输入您对技师评价"
                          rows="3"
                          autosize
                      />
                      <van-button round block :loading="commentloading" type="info" @click.stop="gocomment(item)">提交评论</van-button>
                    </div>
                    <div v-else>{{item.custom_remarks}}</div>
                  </div>
                </van-tab>
                <van-tab v-if="item.masseur_remarks">
                  <template #title> <van-icon name="records" />技师陈述</template>
                  <div>{{item.masseur_remarks}}</div>
                </van-tab>
              </van-tabs>
            </li>
          </ul>
        </div>
      </van-list>
    </div>
    <van-action-sheet v-model:show="showsheet" title="选择支付方式">
      <div class="r-s r-info" style="margin-top:0px;">
        <div class="audio paymethod" style="margin:0px auto;">
          <div class="c-a-info">
            <van-radio-group v-model="paymethod" checked-color="#ff0a00">
              <van-radio name="balance">余额支付（当前余额¥ {{ number.balance }}）</van-radio>
              <van-radio name="wechat">在线微信支付</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="v-btn" style="margin-bottom:10px;"><van-button class="sybtn" :loading="payendloading" @click="dopaynow">立即支付</van-button></div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget, httpput} from "@/libs/api";
import {Dialog,Toast} from "vant";
import {useRouter} from "vue-router";

export default {
  name: 'order',
  setup() {
    const router = useRouter();
    const state = reactive({
      loading:false,
      showsheet:false,
      gopayloading:false,
      commentloading:false,
      payendloading:false,
      finished:false,
      active:1,
      orderlist:[],
      searchinfo:{
        page:0,
        status:1,
      },
      number: {
        balance:'0.00',
        needpaynum:0,
        haspaynum:0,
        finishnum:0
      },
      gopayinfo:[],
      paymethod:'balance',
      gopaytype:'',
    })
    const getyytimestr = (name,daytime,datetime) =>{
      let sday = '';
      if( datetime ){
        let hour = datetime.split(':');
        sday = '上午';
        if( hour[0] >= 12 ) sday = '下午';
      }
      var chooseData = new Date(daytime);
      return name+' '+formatDateDays(chooseData)+"（"+getWeek(daytime)+"）"+(sday?sday:'')+' '+(datetime?datetime:'');
    }
    const getWeek = (dateString) =>{
      var dateArray = dateString.split("-");
      let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
      return "周" + "日一二三四五六".charAt(date.getDay());
    }
    const formatDateDays = (date) => {
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    }
    const gocomment = async (item) => {
      if( !item.custom_remarks ){
        Toast.fail("请输入评论");
        return;
      }
      state.commentloading = true;
      let res = await httpput('/api/order/comment',item);
      if(res.code===0){
        state.commentloading = false;
        let idx = state.orderlist.findIndex((orderone)=>{
          return orderone.id = item.id;
        })
        if(idx!==-1){
          state.orderlist.splice(idx, 1);
        }
        state.orderlist.forEach((items)=>{
          if(items.id == item.id){
            items.custom_remarks = item.custom_remarks;
          }
        })
        state.number.haspaynum--;
        state.number.finishnum++;
      }else{
        Toast.fail(res.msg);
        state.commentloading = false;
        return ;
      }
    }
    const orderapply = (item) =>{
      state.loading = true;
      Dialog.confirm({
        title: '提示',
        message: '确认申请退款？',
      })
      .then(async () => {
        let res = await httpput('/api/order/apply',{oid:item.id});
        if(res.code===0){
          state.loading = false;
          Toast.success(res.msg);
          state.searchinfo.page = 0;
          state.finished = false;
          state.orderlist = [];
          getlist();
        }else{
          state.loading = false;
          Toast(res.msg);
          return ;
        }
      })
      .catch(() => {
        state.loading = false;
      });
    }
    const ordercancel = (item) => {
      state.loading = true;
      Dialog.confirm({
        title: '提示',
        message: '确认取消订单？',
      })
      .then(async () => {
        let res = await httpput('/api/order/cancel',{oid:item.id});
        if(res.code===0){
          state.loading = false;
          let idx = state.orderlist.findIndex((orderone)=>{
            return orderone.id = item.id;
          })
          if(idx!==-1){
            state.orderlist.splice(idx, 1);
          }
          state.number.needpaynum--;
        }else{
          state.loading = false;
          return ;
        }
      })
      .catch(() => {
        state.loading = false;
      });
    }
    const gopayend = (item,type) => {
      state.showsheet = true;
      state.gopaytype = type;
      state.gopayinfo = item;
    }
    const dopaynow = async () =>{
      state.payendloading = true;
      if( state.gopaytype == 'pay' ){
        Dialog.confirm({
          title: '提示',
          message: '确认支付订单？',
        })
        .then(async () => {
          state.showsheet = false;
          let res = await httpput('/api/order/gopay',{oid:state.gopayinfo.id,paymethod:state.paymethod});
          if(res.code===0){
            state.payendloading = false;
            onBridgeReady(res.data);
          } else if( res.code === 200 ) {
            state.payendloading = false;
            state.searchinfo.page = 0;
            state.finished = false;
            state.orderlist = [];
            getlist();
          } else  {
            Toast.fail(res.msg);
            state.payendloading = false;
          }
        })
        .catch(() => {
          state.payendloading = false;
          console.log("cancel");
        });
      } else {
        Dialog.confirm({
          title: '提示',
          message: '确认支付订单尾款？',
        })
        .then(async () => {
          state.showsheet = false;
          let res = await httpput('/api/order/gopayend',{oid:state.gopayinfo.id,paymethod:state.paymethod});
          if(res.code==0){
            state.payendloading = false;
            onBridgeReady(res.data);
          } else if( res.code == 200 ){
            state.payendloading = false;
            state.searchinfo.page = 0;
            state.finished = false;
            state.orderlist = [];
            getlist();
          } else {
            Toast.fail(res.msg);
            state.payendloading = false;
          }
        })
        .catch(() => {
          state.gopayloading = false;
          console.log("cancel");
        });
      }

    }
    const change = (value) => {
      state.searchinfo.status = value;
      state.searchinfo.page = 0;
      state.finished = false;
      state.orderlist = [];
      getlist();
    }
    const getnumber = async () =>{
      let res = await httpget('/api/order/getnumber');
      if(res.code===0){
        state.number = res.data;
      }else{
        return ;
      }
    }
    const getlist = async () =>{
      state.loading = true;
      if(!state.finished){
        state.searchinfo.page++;
      }
      let res = await httpget('/api/order/index',state.searchinfo);
      if(res.code===0){
        state.loading = false;
        if(state.searchinfo.page == 1){
          state.orderlist = [];
        }
        if(res.data.data.length>0){
          res.data.data.forEach((task)=>{
            state.orderlist.push(task);
          })
        }
        if(res.data.data.length<20){
          state.finished = true;
        }
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    const onBridgeReady = (resJson)=>{
      WeixinJSBridge.invoke(
              'getBrandWCPayRequest', resJson,
              function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                  state.searchinfo.page = 0;
                  state.finished = false;
                  getlist();
                  router.push({path: '/order'}).catch(err => {err});
                }
              });
    }
    onMounted(() => {
      getlist();
      getnumber();
    })

    return {
      ...toRefs(state),
      change,
      getlist,
      getyytimestr,
      getnumber,
      ordercancel,
      gopayend,
      orderapply,
      gocomment,
      onBridgeReady,
      dopaynow
    };
  },
}
</script>
